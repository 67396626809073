:root {
  --col-primary: #b5b5b5;
  --col-primary-muted: #666;
  --col-primary-bg: #333;
  --col-accented: #fdfeff;
  --col-accented-muted: #4787a8;
  --col-accented-bg: #004763;
  --col-warn: #fa3b00;
  --col-warn-bg: #441000;
  --col-valid: #9f9;
  --col-valid-bg: #040;
  --border-width: 1px;
  --border-radius: .2rem;
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --col-primary: #1b1b1b;
    --col-primary-muted: #aaa;
    --col-primary-bg: #fff;
    --col-accented: #3888d3;
    --col-accented-muted: #99ccfc;
    --col-accented-bg: #e4f1ff;
    --col-warn: #fa5d29;
    --col-warn-bg: #fff2ee;
    --col-valid: #005300;
    --col-valid-bg: #eaffe3;
    --border-width: 1px;
    --border-radius: .2rem;
  }
}

html {
  color: var(--col-primary);
  background-color: var(--col-primary-bg);
  font-family: Roboto, sans-serif;
  font-size: 100%;
}

body {
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

a {
  color: var(--col-accented);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.warning {
  color: var(--col-warn);
}

.interactable {
  cursor: pointer;
}

#header {
  color: var(--col-accented-muted);
  margin-bottom: 3rem;
}

#header .logo {
  height: 3rem;
  background-color: var(--col-accented-bg);
}

#header .logo__link {
  color: var(--col-accented);
  width: 100%;
  height: 100%;
  place-items: center;
  font-size: 1.5em;
  text-decoration: none;
  display: grid;
}

#header .nav__toggle {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  color: var(--col-accented);
  background-color: var(--col-accented-bg);
  border: .2em solid var(--col-accented-muted);
  z-index: 1;
  border-radius: 50%;
  place-items: center;
  margin: .5rem .5rem -5.4rem auto;
  font-size: 1.5rem;
  transition: all .2s ease-in-out;
  display: grid;
  position: relative;
  top: -3.3rem;
}

#header .nav {
  grid-template-rows: 1fr;
  place-items: center;
  display: none;
}

#header .nav--open {
  display: grid;
}

#header .nav__link {
  color: var(--col-accented-muted);
  width: 90vw;
  text-align: center;
  border: .2em solid var(--col-accented-muted);
  border-radius: var(--border-radius);
  padding: .5rem;
  font-size: 1.5rem;
  text-decoration: none;
}

#header .nav__link:active {
  color: var(--col-warn);
  border-color: var(--col-warn);
}

#header .nav__link--current {
  color: var(--col-primary-muted);
  border-color: var(--col-primary-muted);
  cursor: default;
}

#main {
  width: 95vw;
  margin: 0 auto 5em;
  overflow: hidden;
}

#footer {
  color: var(--col-primary-muted);
  text-align: center;
  grid-template: "madeBy source" 2em
                 "buildTag buildTag" 2em
                 / 1fr 1fr;
  margin-top: auto;
  font-size: .8rem;
  line-height: 2;
  display: grid;
}

#footer > * {
  border-top: 1px solid var(--col-primary-muted);
  color: var(--col-primary-muted);
}

#footer a {
  color: var(--col-accented-muted);
}

#footer p {
  margin: 0;
}

#footer .madeBy {
  grid-area: madeBy;
}

#footer .buildTag {
  grid-area: buildTag;
}

#footer .sourceCode {
  grid-area: source;
}

@media screen and (min-width: 36rem) {
  #footer {
    grid-template: "madeBy buildTag source" 2em
    / 1fr 1fr 1fr;
  }
}

#mealCalculator {
  max-width: 95vw;
  width: 100%;
  grid-gap: 1rem;
  grid-template: "addFood"
                 "foodList"
                 "results"
                 / 1fr;
  margin: 0 auto;
  display: grid;
}

#mealCalculator .addFood {
  grid-area: addFood;
  display: grid;
}

#mealCalculator .addManual {
  display: none;
}

#mealCalculator .addManual--open {
  display: grid;
}

#mealCalculator .foodList {
  grid-area: foodList;
  padding-left: 0;
  list-style: none;
}

#mealCalculator .food > .food__info {
  color: var(--col-primary-muted);
  border: none;
  grid-area: inf;
  place-items: end;
  font-size: .8rem;
}

#mealCalculator .food.food__header {
  color: var(--col-primary-muted);
  text-align: center;
  grid-template: "inf inf inf inf _ __"
                 "nam nam nam wei _ delete"
                 "pro fat car cal _ delete"
                 / 1fr 1fr 1fr 1fr .4fr 1fr;
  gap: .2rem;
  margin-bottom: 1rem;
  display: grid;
}

#mealCalculator .food {
  text-align: center;
  grid-template: "nam nam nam wei _ delete"
                 "pro fat car cal _ delete"
                 / 1fr 1fr 1fr 1fr .4fr 1fr;
  gap: .2rem;
  margin-bottom: 1rem;
  display: grid;
}

#mealCalculator .food > input {
  width: calc(100% - 6px);
}

#mealCalculator .food > * {
  border: var(--border-width) solid var(--col-primary-muted);
  border-radius: var(--border-radius);
  min-height: 1.5rem;
  white-space: nowrap;
  place-items: center;
  display: grid;
}

#mealCalculator .food > input {
  color: var(--col-warn);
  background-color: var(--col-warn-bg);
}

#mealCalculator .food > .valid {
  color: var(--col-valid);
  background-color: var(--col-valid-bg);
}

#mealCalculator .food__name {
  min-height: 2rem;
  white-space: normal;
  grid-area: nam;
  font-weight: 700;
}

#mealCalculator .food__weight {
  white-space: normal;
  grid-area: wei;
  font-weight: 700;
}

#mealCalculator .food__calories {
  grid-area: cal;
}

#mealCalculator .food__protein {
  grid-area: pro;
}

#mealCalculator .food__fat {
  grid-area: fat;
}

#mealCalculator .food__carbs {
  grid-area: car;
}

#mealCalculator .food__delete, #mealCalculator .food__done {
  color: var(--col-warn);
  grid-area: delete;
  place-items: center;
  display: grid;
}

#mealCalculator .food__done {
  color: var(--col-accented-muted);
}

#mealCalculator .food__done.valid {
  color: var(--col-valid);
}

#mealCalculator .food:not(.food__header) > :hover {
  border-color: var(--col-accented-muted);
  color: var(--col-accented);
  background-color: var(--col-accented-bg);
}

#mealCalculator .food:not(.food__header) > .food__delete:hover {
  border-color: var(--col-warn);
  color: var(--col-warn);
  background-color: var(--col-warn-bg);
}

#mealCalculator .food__header .food__delete {
  color: var(--col-primary-muted);
}

#mealCalculator .results {
  text-align: center;
  grid-template: "prec"
                 "port"
                 "total"
                 / 1fr;
  grid-area: results;
  place-content: end;
  display: grid;
}

#mealCalculator .results__percentages {
  grid-template: "head head head"
                 "prot fat carbs"
                 / 1fr 1fr 1fr;
  grid-area: prec;
  gap: .2rem;
  display: grid;
}

#mealCalculator .results__percentages > * {
  border: var(--border-width) solid var(--col-primary-muted);
  border-radius: var(--border-radius);
}

#mealCalculator .resultsPartial {
  grid-template: "head head head"
                 "kcal kcal weight"
                 "prot fat carbs"
                 / 1fr 1fr 1fr;
  gap: .2rem;
  display: grid;
}

#mealCalculator .resultsPartial--portions {
  grid-area: port;
}

#mealCalculator .resultsPartial--total {
  grid-area: total;
}

#mealCalculator .resultsPartial > * {
  border: var(--border-width) solid var(--col-primary-muted);
  border-radius: var(--border-radius);
}

#mealCalculator .results__header {
  color: var(--col-primary-muted);
  border: 0;
  grid-area: head;
  margin: 0;
  line-height: 1.5;
  text-decoration: underline;
}

#mealCalculator .resultsPartial__label {
  color: var(--col-primary-muted);
}

#mealCalculator .resultsPartial__kcal {
  grid-area: kcal;
}

#mealCalculator .resultsPartial__estimate {
  grid-area: esti;
}

#mealCalculator .resultsPartial__protein {
  grid-area: prot;
}

#mealCalculator .resultsPartial__fat {
  grid-area: fat;
}

#mealCalculator .resultsPartial__carbs {
  grid-area: carbs;
}

#mealCalculator .resultsPartial__weight {
  grid-area: weight;
}

@media screen and (min-width: 35rem) {
  #mealCalculator {
    max-width: 60em;
    width: 100%;
    grid-template: "addFood results"
                   "foodList foodList"
                   / 1fr 1fr;
  }

  #mealCalculator .food.food__header {
    text-align: center;
    grid-template: "inf inf inf inf inf inf _ __"
                   "nam pro fat car cal wei _ delete"
                   / 4fr 1fr 1fr 1fr 1fr 1fr .4fr 1fr;
    gap: .2rem;
    margin-bottom: 1rem;
    display: grid;
  }

  #mealCalculator .food {
    text-align: center;
    grid-template: "nam pro fat car cal wei _ delete"
    / 4fr 1fr 1fr 1fr 1fr 1fr .4fr 1fr;
    gap: .2rem;
    margin-bottom: 1rem;
    display: grid;
  }

  #mealCalculator .food__name {
    min-height: auto;
  }

  #mealCalculator .results {
    text-align: center;
    grid-template: "total"
                   "port"
                   "prec"
                   / 1fr;
    grid-area: results;
    display: grid;
  }
}

/*# sourceMappingURL=index.2a623fe0.css.map */
